<template>
  <div id="call-container">
    <div v-if="isAdmin()">
      <ring :open="open"
            :ring="ring"
            :src="ringPath"></ring>
      <i v-if="isCall" @click="hangup" class="el-icon-phone-outline"></i>
    </div>
    <div v-else>
      <i v-if="!isCall && !loading" @click="call" class="el-icon-phone"></i>
      <i v-else-if="!isCall && loading" class="el-icon-loading"></i>
      <i v-else @click="hangup" class="el-icon-phone-outline"></i>
    </div>

    <div class="calling-audio">
      <audio ref="calling-audio" muted></audio>
    </div>
    <div class="called-audio">
      <audio ref="called-audio" controls autoplay></audio>
    </div>
  </div>
</template>

<script>
import Ring from './Ring'
import {getSocket, uid} from '../js/socket'


export default {
  name: "Call",
  components: {
    Ring
  },
  data() {
    return {
      mch_id: null,
      tem_id: null,
      type: null,
      socket: null,
      uid: uid,
      peer: null,
      receiveUser: this.getReceiveUser(),
      dataChannel: null,
      isCall: false,
      devicesConfig: {
        audio: true,
        video: false,
      },
      open: false,
      ring: false,
      ringPath: null,
      audioInputDevices: [],
      loading: false,
    }
  },
  // ?mch_id=34&tem_id=52
  mounted() {
    this.getParam()
    this.socket = getSocket(this.type);
    this.initSocket()
    window.synchronous = this.synchronous
    this.testingEquipment()
    this.openRing()
  },
  methods: {
    async testingEquipment() {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        this.$message('你的设备不支持语音', 'error');
        return;
      }
      navigator.mediaDevices.enumerateDevices()
          .then((devices) => {
            devices.forEach((device) => {
              if (device.kind === 'audioinput') {
                this.audioInputDevices.push(device)
              }
            });
            if (this.audioInputDevices.length < 1) {
              this.$message('请插入麦克风', 'error');
            }
          })
          .catch(function (err) {
            console.log(err.name + ": " + err.message);
          });
    },
    openRing() {
      this.$bus.$on("ring", () => {
        this.ringPath = '/ring/call.mp3';
        this.open = true
      })
    },
    getParam() {
      const {mch_id, tem_id, type} = this.$route.query || {};
      this.type = type !== undefined && type != null ? type : "user";
      this.mch_id = mch_id;
      this.tem_id = tem_id;
    },
    isAdmin(): boolean {
      return this.type === "admin"
    },
    initSocket() {
      this.socket.addEventListener('open', () => {
        console.log("连接成功了")
      })
      this.socket.addEventListener('close', () => {
        console.log("socket error")
        this.closeLocalMedia();
        this.closePeerConnection();
        this.isCall = false;
      })
      this.socket.addEventListener('error', () => {
        console.log("socket error")
        this.closeLocalMedia();
        this.closePeerConnection();
        this.isCall = false;
      })
      this.socket.addEventListener('message', async (message) => {
            console.log("接收到的消息:");
            console.dir(message);
            let channel = JSON.parse(message.data);
            console.log(channel)
            console.log("消息类型:" + channel.code);
            switch (channel.code) {
                // 这是人数变动
              case 0: {
                const userList = JSON.parse(channel.data);
                console.log("人数:" + userList.count);
                break;
              }
                // 收到请求
              case 1: {
                const requestData = JSON.parse(channel.data);
                switch (requestData.type) {
                  case 1: {// 同意
                    // 对方同意之后创建自己的 peer
                    try {
                      this.loading = false
                      this.isCall = true
                      this.setReceiveUser(channel.sendUser);
                      await this.createMedia(channel);
                    } catch (e) {
                      this.loading = false
                      console.log(e)
                      this.hangup();
                      return
                    }
                    // 并给对方发送 offer
                    await this.createOffer(channel);
                    // 接通了~~
                    break;
                  }
                  case 2: {
                    //拒绝
                    const errorMessage = requestData.message !== undefined && requestData.message != null ? requestData.message : '对方拒绝了你的请求！'
                    this.$message(errorMessage, 'warning');
                    this.loading = false
                    break;
                  }
                  case 3: // 正在通话中
                    this.loading = false
                    this.$message('对方正在通话中！', 'warning');
                    break;
                  default:
                    this.loading = false
                    this.$message('错误', 'error');
                }
                break;
              }
                // 收到回复
              case 2:
                if (this.isCall) {
                  this.reply(channel, 3);
                  return;
                }
                this.ring = true
                this.$confirm('客户向你请求通话, 是否同意?', '提示', '同意', '拒绝')
                    .then(async () => {
                      this.ring = false
                      const requestData = JSON.parse(channel.data);
                      await this.$router.push({
                        path: '/',
                        query: {type: 'admin', mch_id: requestData.mch_id, tem_id: requestData.tem_id}
                      });
                      try {
                        await this.createMedia(channel);
                      } catch (e) {
                        console.log(e)
                        this.$message("请插入音频设备", "error")
                        this.reply(channel, 2);
                        return
                      }
                      this.isCall = true;
                      this.reply(channel, 1);
                    })
                    .catch((reason) => {
                      this.ring = false
                      console.log(reason)
                      this.reply(channel, 2);
                    })
                break;
                // 这是ice
              case 3:
                await this.onIce(channel);
                break;
                // 这是offer
              case 4:
                await this.onOffer(channel);
                break;
              case 5:
                await this.onAnswer(channel);
                break;
              case 6:
                this.$message('对方已断开连接！', 'warning');
                this.closeLocalMedia();
                this.closePeerConnection();
                this.isCall = false;
                break;
              case 7:
                this.loading = false
                this.$message(channel.message, 'warning');
                break;
              case 8: {
                let users = JSON.parse(channel.data);
                if (channel.receiveUser === this.receiveUser) {
                  this.receiveUser = null;
                }
                break
              }
            }
          }
      )
    },
    async createMedia(data) {
      this.localStream = await this.getUserMedia(this.devicesConfig);
      const callingAudio = this.$refs["calling-audio"];
      callingAudio.srcObject = this.localStream;
      this.initPeer(data);
    },
    async getUserMedia(constraints): Promise {
      if (navigator.mediaDevices.getUserMedia) {
        return navigator.mediaDevices.getUserMedia(constraints);
      } else if (navigator.webkitGetUserMedia) {
        return new Promise((resolve, reject) => {
          navigator.webkitGetUserMedia(constraints, stream => {
            resolve(stream)
          }, error => {
            reject(error)
          })
        });
      } else if (navigator.mozGetUserMedia) {
        return new Promise((resolve, reject) => {
          navigator.mozGetUserMedia(constraints, stream => {
            resolve(stream)
          }, error => {
            reject(error)
          })
        })
      } else if (navigator.msGetUserMedia) {
        return new Promise((resolve, reject) => {
          navigator.msGetUserMedia(constraints, stream => {
            resolve(stream)
          }, error => {
            reject(error)
          })
        })
      } else if (navigator.getUserMedia) {
        return new Promise((resolve, reject) => {
          navigator.getUserMedia(constraints, stream => {
            resolve(stream)
          }, error => {
            reject(error)
          })
        })
      }
    },

    initPeer(data) {
      // 创建输出端 PeerConnection
      let PeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
      const config = {
        iceServers: [
          {
            urls: "stun:stun.l.google.com:19302"
          }
        ]
      };
      this.peer = new PeerConnection(config);
      // 创建 RTCDataChannel 对象
      this.dataChannel = this.peer.createDataChannel("dataChannel", {
        // 保证到达顺序
        ordered: true,
        reliable: true
      });
      //文本聊天
      this.dataChannel.onmessage = this.receiveMessage;
      this.dataChannel.onopen = this.dataChannelStateChange;
      this.dataChannel.onclose = this.dataChannelStateChange;
      this.dataChannel.onerror = this.dataChannelError;

      // 添加本地流
      this.localStream.getTracks().forEach((track) => {
        this.peer.addTrack(track, this.localStream);
      });
      // 去掉主叫
      // peer.addStream(localStream);
      // 监听ICE候选信息 如果收集到，就发送给对方
      this.peer.onicecandidate = (event) => {
        if (event.candidate) {
          const replyData = {
            code: 3,
            message: "ICE候选信息",
            sendUser: uid,
            receiveUser: data.sendUser,
            iceCandidate: event.candidate
          }
          this.send(JSON.stringify(replyData));
        }
      };
      //文本聊天
      this.peer.ondatachannel = e => {
        this.dataChannel = e.channel;
        this.dataChannel.onmessage = this.dataChannel.receiveMessage;
        this.dataChannel.onopen = this.dataChannel.dataChannelStateChange;
        this.dataChannel.onclose = this.dataChannel.dataChannelStateChange;
        this.dataChannel.onerror = this.dataChannel.dataChannelError;
      }

      // 监听是否有媒体流接入，如果有就赋值给 rtcB 的 src
      this.peer.ontrack = (event) => {
        const calledAudio = this.$refs["called-audio"];
        calledAudio.srcObject = event.streams[0];
      };
    }
    ,
    call() {
      if (!this.loading) {
        this.throttle(this.calling(), 1000);
      }
      this.loading = true
    }
    ,
    calling() {
      const callData = {
        code: 2,
        message: "呼叫信令",
        sendUser: uid,
        receiveUser: null,
        data: {
          mch_id: this.mch_id,
          tem_id: this.tem_id,
          type: 0
        }
      }
      this.send(JSON.stringify(callData));
    },
    reply(data, type) {
      const replyData = {
        code: 1,
        message: "回复信令",
        sendUser: uid,
        receiveUser: data.sendUser,
        data: {
          type: type
        }
      }
      this.send(JSON.stringify(replyData));
    }
    ,
// 创建并发送 offer
    async createOffer(data) {
      const offerOption = {
        offerToReceiveAudio: true,
        offerToReceiveVideo: false
      }
      try {
        // 创建offer
        let offer = await this.peer.createOffer(offerOption);
        // 呼叫端设置本地 offer 描述
        await this.peer.setLocalDescription(offer);
        // 给对方发送 offer
        const replyData = {
          code: 4,
          message: "offer候选信息",
          sendUser: uid,
          receiveUser: data.sendUser,
          sessionDescription: offer
        }
        this.send(JSON.stringify(replyData));
      } catch (e) {
        console.log('createOffer: ', e);
      }
    }
    ,
// 接收offer并发送 answer
    async onOffer(data) {
      try {
        // 接收端设置远程 offer 描述
        await this.peer.setRemoteDescription(JSON.parse(data.sessionDescription));
        // 接收端创建 answer
        let answer = await this.peer.createAnswer();
        // 接收端设置本地 answer 描述
        await this.peer.setLocalDescription(answer);
        // 给对方发送 answer
        const replyData = {
          code: 5,
          message: "answer信息",
          sendUser: uid,
          receiveUser: data.sendUser,
          answer: answer
        }
        this.send(JSON.stringify(replyData));
      } catch (e) {
        console.log('onOffer: ', e);
      }
    }
    ,
// 接收answer
    async onAnswer(data) {
      try {
        // 呼叫端设置远程 answer 描述
        await this.peer.setRemoteDescription(JSON.parse(data.answer));
      } catch (e) {
        console.log('onAnswer: ', e);
      }
    }
    ,
// 接收 ICE 候选
    async onIce(data) {
      try {
        // 设置远程 ICE
        await this.peer.addIceCandidate(JSON.parse(data.iceCandidate));
      } catch (e) {
        console.log('onAnswer: ', e);
      }
    }
    ,
    send(message) {
      this.socket.send(message)
    }
    ,
//发送文本
    synchronous(data, person, type) {
      if (this.dataChannel && this.dataChannel.readyState === 'open') {
        const modelData = {
          code: 0,
          message: `第${person}人称${type === 0 ? "旋转" : "移动"}模型信令`,
          sendUser: uid,
          person: person,
          type: type,
          data: data
        };
        console.log(modelData)
        this.dataChannel.send(JSON.stringify(modelData));
      }
    }
    ,

//文本对方传过来的数据
    receiveMessage(e) {
      const msg = JSON.parse(e.data);
      if (msg.code === 0) {
        if (window.game && window.game.cameraControl) {
          if (msg.person !== window.game.cameraControl.cameraPerson) {
            window.game.cameraControl.switchCameraPerson();
          }
          console.log(msg.message);
          const data = msg.data;
          if (window.game.cameraControl.cameraPerson === 1) {
            if (msg.type === 0) {
              console.log(data)
              window.game.cameraControl.setFirstCameraRotation(data);
            } else if (msg.type === 1) {
              const target = JSON.parse(data);
              console.log(target.target)
              window.game.cameraControl.setFirstCameraPosition(target.target);
            }
          } else if (window.game.cameraControl.cameraPerson === 3) {
            if (msg.type === 0) {
              const target = JSON.parse(data);
              console.log(target)
              window.game.cameraControl.setThirdCameraAlphaBeta(target);
            } else if (msg.type === 1) {
              const target = JSON.parse(data);
              console.log(target.translate)
              window.game.cameraControl.setThirdCameraMove(target.translate);
            }
          }
        }
      } else {
        this.$message.info(msg)
      }
    }
    ,

    dataChannelStateChange() {
      const readyState = this.dataChannel.readyState;
      if (readyState === 'open') {
        console.log("open")
      } else if (readyState === 'close') {
        this.hangup()
      } else {
        console.log(this.dataChannel.readyState)
      }
    }
    ,

    dataChannelError(error) {
      this.hangup()
      console.log("Data Channel Error:", error);
    }
    ,
    hangup() { // 挂断通话
      console.log('hangup');
      if (this.socket !== null && this.socket !== undefined) {
        const rejectData = {
          code: 6,
          message: "挂断信令",
          sendUser: uid,
          receiveUser: this.getReceiveUser(),
        };
        this.send(JSON.stringify(rejectData));
        this.closeLocalMedia();
        this.closePeerConnection();
        this.isCall = false;
      }
      this.$message('已断开连接！', 'info');
    }
    ,

//关闭流通道
    closeLocalMedia() {
      this.ring = false
      if (this.localStream && this.localStream.getTracks()) {
        this.localStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
      this.localStream = null;
    }
    ,

//关闭本地媒体流链接
    closePeerConnection() {
      console.log('close RTCPeerConnection!');
      if (this.peer) {
        this.peer.close();
        this.peer = null;
      }
    }
    ,
    setReceiveUser(uid) {
      if (uid !== undefined && uid !== null && uid !== '') {
        this.receiveUser = uid;
        window.sessionStorage.setItem("receiveUser", uid);
      }
    }
    ,
    getReceiveUser(): string {
      return this.receiveUser !== undefined && this.receiveUser !== null && this.receiveUser !== '' ? this.receiveUser : window.sessionStorage.getItem("receiveUser");
    },
    throttle(func, wait) {
      let previous = 0;
      return () => {
        let now = Date.now();
        if (now - previous > wait) {
          func.apply(this, arguments);
          previous = now;
        }
      }
    }
  },
  beforeDestroy() {
    this.$bus.off("ring")
  }
}
</script>

<style scoped>
audio {
  position: absolute;
  right: 9000px;
  z-index: -999;
}

.el-icon-phone-outline, .el-icon-phone, .el-icon-loading {
  color: #000;
  background: rgba(255, 255, 255, .8);
  width: 26px;
  height: 29px;
  line-height: 29px;
  text-align: center;
  border-radius: 50%;
}
</style>
